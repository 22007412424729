import React, {useEffect, useState} from 'react';
import {Link, NavLink} from 'react-router-dom';

import './video-section.styles.css';
import {useInView} from 'react-intersection-observer';
import {useWindowMedia} from '../../hooks/useWindowMedia';
import {VideoSectionDataT} from 'hooks/types';
import useContentful from 'hooks/useContentful';

const VideoSection = () => {
  const [videoSectionData, setVideoSectionData] = useState<VideoSectionDataT>();
  const [isVisible, setIsVisible] = useState(false);

  const [ref, inView] = useInView({
    triggerOnce: true,
  });
  const {windowLargePlus} = useWindowMedia();
  const {getVideoSectionData} = useContentful();

  useEffect(() => {
    if (inView) {
      setIsVisible(true);
    }
  }, [inView]);

  useEffect(() => {
    getVideoSectionData().then((content) => {
      if (content) {
        setVideoSectionData(content);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const slideRightToLeftStyle = {
    opacity: isVisible ? 1 : 0,
    transform: isVisible ? 'translateX(0%)' : 'translateX(100%)',
    transition: 'opacity 3s ease, transform 3s ease',
    border: 0,
  };

  return (
    <div ref={ref} className='video-section'>
      <div className='container'>
        <div className='actions-container'>
          <div className='content-box'>
            <h3>{videoSectionData?.title}</h3>
            <p>{videoSectionData?.subtitle}</p>
          </div>
          <div className='actions-box'>
            <Link to='/' className='home-button actions-btn-1' href=''>
              LEARN MORE
            </Link>
            <NavLink
              to={'/signin'}
              className='home-button actions-btn-2'
              href=''>
              TRY IT FOR FREE
            </NavLink>
          </div>
        </div>
        {windowLargePlus && (
          <div className='image-container'>
            <iframe
              style={slideRightToLeftStyle}
              src={videoSectionData?.embeddedVideoUrl}
              title='YouTube video player'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share fullscreen'
              allowFullScreen
            />
          </div>
        )}
      </div>
    </div>
  );
};
export default VideoSection;
