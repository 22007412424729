import React, {useEffect, useState} from 'react';

import {SvgIcon} from '@material-ui/core';
import {ArrowRightAlt} from '@material-ui/icons';
import {SnackbarToast} from 'modules/molecules';
import {SectionsRefT} from '../../home.types';
import {sendEmail} from './api/form-section.api';
import BubblesBackground from './components/bubbles-background/bubbles-background';
import './form-section.styles.css';
import useContentful from 'hooks/useContentful';
import {FormSectionDataT} from 'hooks/types';

const FormSection = ({reference}: {reference: SectionsRefT}) => {
  const [formSectionData, setFormSectionData] = useState<FormSectionDataT>();
  const [emailMessage, setEmailMessage] = useState<string>();
  const [isError, setIsError] = useState(false);

  const {getFormSectionData} = useContentful();

  const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = ({
    currentTarget,
  }) => {
    setEmailMessage(currentTarget.value);
  };

  const handleEmailSend = () => {
    if (emailMessage)
      sendEmail(emailMessage)
        .then((res) => {
          if (res.status !== 200) setIsError(true);
        })
        .catch(() => {
          setIsError(true);
        });
  };

  useEffect(() => {
    getFormSectionData().then((data) => {
      setFormSectionData(data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section ref={reference} id='sign-up-section' className='form-section'>
      {isError && (
        <SnackbarToast
          message='Oops, something went wrong. Try again later'
          onCloseHandler={() => {
            setIsError(false);
          }}
          variant='error'
        />
      )}

      <div className='container'>
        <div className='form-section__upper'>
          <h2 className='text-white'>{formSectionData?.title}</h2>
          <p className='large text-white'>{formSectionData?.subtitle}</p>
        </div>
        <div className='form-section__lower'>
          <div className='form-section__inputs-wrapper'>
            <input
              placeholder={formSectionData?.inputPlaceholder}
              type='text'
              className='form-section__input'
              onChange={handleInputChange}
            />
          </div>
          <button
            onClick={handleEmailSend}
            className='home-button form-section__btn'>
            {formSectionData?.actionButtonText}
            <SvgIcon component={ArrowRightAlt} />
          </button>
        </div>
      </div>
      <BubblesBackground />
    </section>
  );
};
export default FormSection;
