import React, {useEffect, useRef} from 'react';
import {FeaturesContentT} from '../features-section.types';

import {useInView} from 'react-intersection-observer';
import {useWindowMedia} from 'modules/pages/home/hooks/useWindowMedia';

export const FeaturesContent = ({
  tab,
  isOptionOdd,
  isClicked,
}: FeaturesContentT) => {
  const activeImgRef = useRef<HTMLImageElement>(null);
  const activeContentRef = useRef<HTMLDivElement>(null);
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  const {windowMediumPlus} = useWindowMedia();
  const {tabName, tabImage, tabDescription} = tab;

  useEffect(() => {
    const activeImg = activeImgRef.current;
    const activeContent = activeContentRef.current;
    if ((isClicked && activeImg) || (!isClicked && inView && activeImg)) {
      activeImg.style.transform = 'translateX(0)';
      activeImg.style.opacity = '1';
    }

    if (
      (isClicked && activeContent) ||
      (!isClicked && inView && activeContent)
    ) {
      activeContent.style.opacity = '1';
    }
  }, [tab, inView, isClicked]);

  return (
    <div ref={ref} className='features-content container'>
      <div
        ref={activeContentRef}
        key={tabName}
        className='features-content-box'
        style={isOptionOdd ? {order: 2, flex: 7} : {flex: 5}}>
        <h4>{tabName}</h4>
        <p>{tabDescription}</p>
      </div>

      {windowMediumPlus && (
        <div
          style={isOptionOdd ? {flex: 5} : {flex: 7}}
          className={`features-image-box`}>
          <img
            key={tabImage}
            ref={activeImgRef}
            style={{
              transform: isOptionOdd ? 'translateX(-100%)' : 'translateX(100%)',
            }}
            className={isOptionOdd ? 'reverse' : ''}
            src={tabImage}
            alt=''
          />
        </div>
      )}
    </div>
  );
};
