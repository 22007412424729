import {createClient} from 'contentful';
import {
  AboutCardsDataT,
  FeaturesSectionDataT,
  FooterDataT,
  FormSectionDataT,
  HeroSectionDataT,
  PartnersSectionContentfulT,
  PrivacyPolicyDataT,
  VideoSectionDataT,
} from './types';

const useContentful = () => {
  const client = createClient({
    space: 'xt7dneziutaa',
    accessToken: 'yXt4E0a5z8RaBTN-aK9GcoxeKuu5GuruG3cUd_ocwDg',
  });

  const getHeroSectionData = async () => {
    try {
      const response = await client.getEntries({
        content_type: 'hero',
      });

      const field = response.items[0].fields as HeroSectionDataT;
      const {title, subtitle, media} = field;
      const image = media.fields.file.url;

      return {title, subtitle, image};
    } catch (err) {
      console.error(err);
    }
  };

  const getAboutCardsData = async () => {
    try {
      const response = await client.getEntries({
        content_type: 'aboutCards',
      });
      const field = response.items[0].fields as AboutCardsDataT;
      const {
        card1Title,
        card1Description,
        card2Title,
        card2Description,
        card3Title,
        card3Description,
      } = field;
      return [
        {title: card1Title, description: card1Description},
        {title: card2Title, description: card2Description},
        {title: card3Title, description: card3Description},
      ];
    } catch (err) {
      console.error(err);
    }
  };

  const getVideoSectionData = async () => {
    try {
      const response = await client.getEntries({
        content_type: 'videoSection',
      });

      const field = response.items[0].fields as VideoSectionDataT;
      const {title, subtitle, embeddedVideoUrl} = field;

      return {title, subtitle, embeddedVideoUrl};
    } catch (err) {
      console.error(err);
    }
  };

  const getFeaturesSectionData = async () => {
    try {
      const response = await client.getEntries({
        content_type: 'featuresSection',
      });

      const field = response.items[0].fields as FeaturesSectionDataT;
      const {tabs, images} = field;

      const sanitizedTabs = tabs.map((tab) => {
        const tabImageField = images.find(
          (image) => image.fields.title === tab.tabName,
        );

        const tabImage = `https:${tabImageField?.fields.file.url}`;
        return {tabImage, ...tab};
      });
      return sanitizedTabs;
    } catch (err) {
      console.error(err);
    }
  };

  const getFormSectionData = async () => {
    try {
      const response = await client.getEntries({
        content_type: 'formSection',
      });

      const data = response.items[0].fields as FormSectionDataT;
      return data;
    } catch (err) {
      console.error(err);
    }
  };

  const getPartnersSectionData = async () => {
    try {
      const response = await client.getEntries({
        content_type: 'partnersSection',
      });

      const data = response.items[0].fields as PartnersSectionContentfulT;
      const {partners: partnersMedia, title} = data;

      const partners = partnersMedia.map(
        (data) => 'https:' + data.fields.file.url,
      );

      return {title, partners};
    } catch (err) {
      console.error(err);
    }
  };

  const getFooterData = async () => {
    try {
      const response = await client.getEntries({
        content_type: 'footer',
      });

      const data = response.items[0].fields as FooterDataT;
      return data;
    } catch (err) {
      console.error(err);
    }
  };

  const getPrivacyPolicyData = async () => {
    try {
      const response = await client.getEntries({
        content_type: 'privacyPolicy',
      });

      const data = response.items[0].fields as PrivacyPolicyDataT;

      console.log(data);
      return data;
    } catch (err) {
      console.error(err);
    }
  };

  return {
    getHeroSectionData,
    getAboutCardsData,
    getVideoSectionData,
    getFeaturesSectionData,
    getFormSectionData,
    getPartnersSectionData,
    getFooterData,
    getPrivacyPolicyData,
  };
};

export default useContentful;
