import React, {useEffect, useState} from 'react';

import {SvgIcon} from '@material-ui/core';

import {FeaturesContent} from './components/featuresContent';

// import {featureOptions as options} from './components/helpers';

import './features-section.styles.css';
import {SectionsRefT} from '../../home.types';
import useContentful from 'hooks/useContentful';
import {FeaturesTabT} from 'hooks/types';
import {LayersOutlined} from '@material-ui/icons';

const FeaturesSection = ({reference}: {reference: SectionsRefT}) => {
  const [featureTabs, setFeatureTabs] = useState<FeaturesTabT[]>([]);
  const [isClicked, setIsClicked] = useState(false);
  const [activeOption, setActiveOption] = useState<FeaturesTabT>(
    featureTabs[0],
  );

  const {getFeaturesSectionData} = useContentful();

  const isOptionOdd =
    featureTabs.findIndex((tab) => tab.tabName === activeOption.tabName) % 2 !==
    0;

  useEffect(() => {
    getFeaturesSectionData().then((tabs) => {
      if (tabs) {
        setActiveOption(tabs[0]);
        setFeatureTabs(tabs);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section ref={reference} id='features-section' className='features-section'>
      <div className='features-bar '>
        <div className='container'>
          <div className='features-bar__text-box '>
            <h2 className='text-white'>Explore Features</h2>
            <p className='large text-white'>
              Freightposter is more than a product, its a way of thinking
            </p>
          </div>
          <div className='features-bar__options-box'>
            {featureTabs.map((tab, i) => {
              const {tabName} = tab;
              const highlightedOption =
                tabName === activeOption.tabName ? 'activeOption' : '';

              return (
                <div
                  key={i}
                  onClick={() => {
                    setActiveOption(tab);
                    setIsClicked(true);
                  }}
                  className={`features-bar__option ${highlightedOption}`}>
                  <SvgIcon component={LayersOutlined} color='inherit' />
                  <h5 className={highlightedOption}>{tabName}</h5>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {featureTabs.length && (
        <FeaturesContent
          isClicked={isClicked}
          isOptionOdd={isOptionOdd}
          tab={activeOption}
        />
      )}
    </section>
  );
};
export default FeaturesSection;
