import React from 'react';
import {Permissions} from 'shared/constants/Permissions';

export const driverConfig = [
  {
    auth: [Permissions.TOUR_ASSIGNEE],
    routes: [
      {
        path: '/driver',
        exact: true,
        component: React.lazy(
          () => import('./driver-details/driver-details.page'),
        ),
      },
    ],
  },
];
