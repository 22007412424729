import React from 'react';

import './bubbles-background.styles.css';

const BubblesBackground = () => {
  return (
    <section className='sticky'>
      <div className='bubbles'>
        <div className='bubble'></div>
        <div className='bubble'></div>
        <div className='bubble'></div>
        <div className='bubble'></div>
        <div className='bubble'></div>
        <div className='bubble'></div>
        <div className='bubble'></div>
        <div className='bubble'></div>
        <div className='bubble'></div>
        <div className='bubble'></div>
      </div>
    </section>
  );
};

export default BubblesBackground;
