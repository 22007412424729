import React, {useState, useEffect} from 'react';
import {SectionsRefT} from '../../home.types';

import useContentful from 'hooks/useContentful';

type HeroDataT = {
  title: string;
  subtitle: string;
  image: string;
};

const HeroSection = ({
  reference,
  inView,
}: {
  reference: SectionsRefT;
  inView: boolean;
}) => {
  const [heroData, setHeroData] = useState<HeroDataT>();
  const [isVisible, setIsVisible] = useState(false);

  const {getHeroSectionData} = useContentful();

  useEffect(() => {
    if (inView) {
      setIsVisible(true);
    }
  }, [inView]);

  useEffect(() => {
    getHeroSectionData().then((content) => {
      if (content) {
        setHeroData(content);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const imageStyle = {
    opacity: isVisible ? 1 : 0,
    transform: isVisible ? 'translateY(0%)' : 'translateY(100%)',
    transition: 'opacity 3s ease, transform 3s ease',
    padding: isVisible ? '1rem' : 0,
    'border-radius': isVisible ? '16px' : 'unset',
    'background-color': 'white',
  };

  return (
    <section ref={reference} className='hero-section container'>
      <div className='hero-content'>
        <h1 className='text-white'>{heroData?.title}</h1>
        <p className='text-white'>{heroData?.subtitle}</p>
      </div>
      <div className='hero-image'>
        <img src={`https:${heroData?.image}`} alt='hero' style={imageStyle} />
      </div>
    </section>
  );
};

export default HeroSection;
