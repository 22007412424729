import React from 'react';

export const authRouteConfig = [
  {
    routes: [
      {
        path: '/signin',
        exact: true,
        component: React.lazy(() => import('./signin/signin.page')),
      },
    ],
  },
  {
    routes: [
      {
        path: '/signup/select',
        exact: true,
        component: React.lazy(
          () => import('./signup-select/signup-select.page'),
        ),
      },
    ],
  },
  {
    routes: [
      {
        path: '/signup/shipper',
        component: React.lazy(() => import('./signup/signup.page')),
      },
    ],
  },
  {
    routes: [
      {
        path: '/signup/carrier',
        component: React.lazy(() => import('./signup/signup.page')),
      },
    ],
  },
  {
    routes: [
      {
        path: '/signup-complete',
        component: React.lazy(
          () => import('./signup-complete/signup-complete.page'),
        ),
      },
    ],
  },
  {
    routes: [
      {
        path: '/forget-password',
        component: React.lazy(
          () => import('./forget-password/forget-password.page'),
        ),
      },
    ],
  },
  {
    routes: [
      {
        path: '/reset-password',
        component: React.lazy(() => import('./new-password/new-password.page')),
      },
    ],
  },
  {
    routes: [
      {
        path: '/error-pages/error-404',
        component: React.lazy(() => import('../errorPages/Error404/index')),
      },
    ],
  },
];
