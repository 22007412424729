import React from 'react';

import {Box, Grid} from '@material-ui/core';
import {GridContainer} from '@crema';
import AppCard from '@crema/core/AppCard';

import {FormLoadCreateDetailsProps} from './form-load-create-details.types';
import {loadAccesories} from 'shared/constants/AppConst';

import {
  FormMultipleSelectPicker,
  FormSubtitle,
  FormTextArea,
  FormTextField,
} from 'modules/atoms';

export const FormLoadCreateDetails: React.FC<FormLoadCreateDetailsProps> = (
  props,
) => {
  return (
    <>
      <FormSubtitle label='Load Details' />
      <AppCard style={{padding: 20}} mb={5}>
        <Box style={{width: '100%'}}>
          <GridContainer>
            <Grid item xs={12} md={6}>
              {/* Load Accessories */}
              <Box mb={{xs: 4, xl: 6}}>
                <FormMultipleSelectPicker
                  name='loadAccessoriesSelect'
                  items={loadAccesories}
                  label='Select Load Accesories *'
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              {/* Weight */}
              <Box mb={{xs: 4, xl: 6}}>
                <FormTextField
                  label='Weight(LBS) *'
                  name='weight'
                  type='number'
                />
              </Box>
            </Grid>
          </GridContainer>
          <GridContainer>
            <Grid item xs={12} md={6}>
              {/* Description */}
              <Box mb={{xs: 4, xl: 6}}>
                <FormTextArea
                  label='Any additional Load Comments'
                  name='description'
                  type='text'
                  rowsMax={5}
                />
              </Box>
              {/* Customer Refference Number */}
              <Box mb={{xs: 4, xl: 6}}>
                <FormTextField
                  label='Shipper Reference Number *'
                  name='refNumber'
                  type='text'
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              {/* Commodity */}
              <Box mb={{xs: 4, xl: 6}}>
                <FormTextArea
                  label='Commodity *'
                  name='commodity'
                  type='text'
                  rowsMax={5}
                />
              </Box>
            </Grid>
          </GridContainer>
        </Box>
      </AppCard>
    </>
  );
};
