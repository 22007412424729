import React, {useEffect, useState} from 'react';
import {useFormikContext, useField} from 'formik';
import ReactGoogleMapLoader from 'react-google-maps-loader';
import ReactGooglePlacesSuggest from 'react-google-places-suggest';
import {GoogleMap, Marker} from '@react-google-maps/api';

import {TextField} from '@material-ui/core';

import {FormGooglePlacesProps} from './form-google-places.types';

export const FormGooglePlaces: React.FC<FormGooglePlacesProps> = (props) => {
  const {setFieldValue} = useFormikContext<string>();
  const [field, meta] = useField('googlePlaceName' + props.location.id);
  const [lat] = useField('googlePlaceLat' + props.location.id);
  const [lng] = useField('googlePlaceLng' + props.location.id);

  const initialCenter = {lat: 39.8282, lng: -98.5795};
  const [center, setCenter] = useState(initialCenter);
  const [search, setSearch] = useState('');

  const errorText = meta.error && meta.touched ? meta.error : '';

  useEffect(() => {
    setFieldValue(
      'googlePlaceName' + props.location.id,
      props.location.placeName,
    );
  }, []);

  const handleInputChange = (e: any) => {
    setSearch(e.target.value);
    setFieldValue('googlePlaceName' + props.location.id, e.target.value);
    setFieldValue('googlePlaceLat' + props.location.id, 0);
    setFieldValue('googlePlaceLng' + props.location.id, 0);
  };
  const handleSelectSuggest = (
    geocodedPrediction: any,
    originalPrediction: any,
  ) => {
    setSearch('');
    setFieldValue(
      'googlePlaceName' + props.location.id,
      geocodedPrediction.formatted_address,
    );
    setFieldValue(
      'googlePlaceLat' + props.location.id,
      geocodedPrediction.geometry.location.lat(),
    );
    setFieldValue(
      'googlePlaceLng' + props.location.id,
      geocodedPrediction.geometry.location.lng(),
    );
    setCenter({
      lat: geocodedPrediction.geometry.location.lat() ?? 0,
      lng: geocodedPrediction.geometry.location.lng() ?? 0,
    });
  };

  return (
    <ReactGoogleMapLoader
      params={{
        key: process.env.REACT_APP_GOOGLE_MAP_KEY ?? '',
        libraries: 'places,geocode',
      }}
      render={(googleMaps) =>
        googleMaps && (
          <>
            <ReactGooglePlacesSuggest
              googleMaps={googleMaps}
              autocompletionRequest={{
                input: search,
                //: Vraticemo ovo **** componentRestrictions: {country: 'us'},
              }}
              onSelectSuggest={handleSelectSuggest}
              textNoResults='No results.'
              customRender={(prediction) => (
                <div
                  className='customWrapper'
                  style={{backgroundColor: '#393D4B'}}>
                  {prediction ? prediction.description : 'No results.'}
                </div>
              )}>
              <TextField
                name={'googlePlaceName' + props.location.id}
                style={{width: '100%'}}
                type='text'
                variant='outlined'
                value={field.value ?? ''}
                label='Search a location'
                placeholder='Search a location'
                helperText={errorText}
                error={!!errorText}
                onChange={handleInputChange}
                required
              />
            </ReactGooglePlacesSuggest>

            {center && (
              <GoogleMap
                mapContainerStyle={{width: '100%', height: 300}}
                center={center}
                zoom={
                  center.lat === initialCenter.lat &&
                  center.lng === initialCenter.lng
                    ? 4
                    : 20
                }>
                {(lat.value ?? 0) !== 0 && (
                  <Marker position={{lat: lat.value, lng: lng.value}} />
                )}
              </GoogleMap>
            )}
          </>
        )
      }
    />
  );
};
