import * as yup from 'yup';
import {FormLocation} from './form-load-create-locations.types';
import {PhoneNumberValidator} from '../../../shared/validators';

export const FormLoadCreateLocationsValidationSchema = (
  locations: FormLocation[],
) => {
  let rules = {};
  for (const loc of locations) {
    rules = {
      ...rules,
      ['googlePlaceName' + loc.id]: yup
        .string()
        .required('Please select a valid location.')
        .when('googlePlaceLat' + loc.id, {
          is: (googlePlaceLat) => {
            return googlePlaceLat === 0;
          },
          then: yup
            .string()
            .test('dummy', 'Please select a valid location', (item) => {
              return false;
            }),
        }),
      ['locationName' + loc.id]: yup
        .string()
        .min(3, 'Please enter the name with more then 3 characters.')
        .required('Please enter the facility name.'),
      ['contactPhone' + loc.id]: new yup.string()
        .notRequired()
        .nullable()
        .test(
          'phoneNumber',
          PhoneNumberValidator.errorMessage,
          function (value) {
            if (typeof value === 'string') {
              return new PhoneNumberValidator().validate(value);
            }
            return true;
          },
        ),
    };
  }
  return yup.object(rules);
};
