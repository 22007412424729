export const emptyLocation = ({id, typeId}: {id: number; typeId: number}) => {
  return {
    id: id,
    placeName: '',
    lat: 0.0,
    long: 0,

    typeId: typeId,
    name: '',
    contactName: '',
    contactPhone: '',
    number: '',
    saveLocation: false,
  };
};
