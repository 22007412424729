import React from 'react';

import {TableRow, TableBody, TableCell, Chip} from '@material-ui/core';

import {TableViewBodyProps} from './table-view-body.types';

export const TableViewBody = (props: TableViewBodyProps) => {
  const {data, columns, actions} = props;

  const styleColorForRow = (additionFunc: any, row: any) => {
    return additionFunc ? {backgroundColor: additionFunc(row)} : {};
  };

  return (
    <>
      <TableBody>
        {data.map((row, index) => {
          const rowFunctions =
            row['status'] === 'Canceled'
              ? row['actions'].slice(2)
              : row['actions'];

          return (
            <TableRow hover tabIndex={-1} key={index}>
              {columns.map((col, index) => {
                if (col.key === 'status') {
                  return (
                    <TableCell key={index}>
                      <Chip
                        style={{
                          backgroundColor:
                            row['status'] === 'Canceled' ? '#f04f47' : '',
                        }}
                        label={
                          col.itemRow
                            ? col.itemRow(row)
                            : col.item
                            ? col.item(row[col.key])
                            : row[col.key]
                        }
                      />
                    </TableCell>
                  );
                }

                return (
                  <TableCell key={index} align='left'>
                    {col.itemRow
                      ? col.itemRow(row)
                      : col.item
                      ? col.item(row[col.key])
                      : row[col.key]}
                  </TableCell>
                );
              })}
              {rowFunctions && rowFunctions.length > 0 && (
                <TableCell key={row.length} align='left'>
                  {rowFunctions.map((actionId: string) => {
                    const act = actions.filter(
                      (action) => action.id === actionId,
                    )[0];
                    const item = {
                      ...act.item,
                      props: {
                        ...act.item.props,
                        style: styleColorForRow(act.colorAdditionFunc, row),
                        children:
                          act.item.props.children +
                          (act.nameAdditionFunc
                            ? act.nameAdditionFunc(row)
                            : ''),
                        onClick: () => {
                          act.action(row);
                        },
                      },
                    };
                    return (
                      <span key={actionId} style={{marginRight: 5}}>
                        {item}
                      </span>
                    );
                  })}
                </TableCell>
              )}
            </TableRow>
          );
        })}
      </TableBody>
    </>
  );
};
