import React from 'react';

import {Box, Button} from '@material-ui/core';
import AppCard from '@crema/core/AppCard';

import {
  FormLoadCreateLocationsProps,
  FormLocation,
} from './form-load-create-locations.types';
import {emptyLocation} from './form-load-create-locations.constants';

import {FormSubtitle} from 'modules/atoms';
import {FormLocationSelector} from 'modules/molecules';

export const FormLoadCreateLocations: React.FC<FormLoadCreateLocationsProps> = (
  props,
) => {
  const addNewItem = () => {
    let id = 0;
    props.locations.forEach((loc: FormLocation) => {
      if (loc.id >= id) {
        id = loc.id + 1;
      }
    });
    const newLocation = emptyLocation({id, typeId: 1});
    props.setLocations(props.locations.concat([newLocation]));
  };
  const removeLocation = (id: number) => {
    const newLocations = props.locations.filter(
      (loc: FormLocation) => loc.id !== id,
    );
    props.setLocations(newLocations);
  };
  return (
    <>
      <FormSubtitle label='Locations' />
      {props.locations.map((location: FormLocation) => {
        return (
          <FormLocationSelector
            key={location.id}
            location={location}
            removeLocation={removeLocation}
            savedLocations={props.savedLocations}
          />
        );
      })}
      <AppCard style={{padding: 20}} mb={5}>
        <Box display='flex' justifyContent='center'>
          <Button variant='contained' color='primary' onClick={addNewItem}>
            Add extra stop
          </Button>
        </Box>
      </AppCard>
    </>
  );
};
