import React, {useEffect, useState} from 'react';

import {SvgIcon} from '@material-ui/core';
import {Email, LocationOnOutlined, PhoneRounded} from '@material-ui/icons';
import {Link, NavLink} from 'react-router-dom';
import {SectionsRefT} from '../../home.types';
import {useWindowMedia} from '../../hooks/useWindowMedia';
import './footer.styles.css';
import {FooterDataT} from 'hooks/types';
import useContentful from 'hooks/useContentful';

const FooterComponent = ({reference}: {reference: SectionsRefT}) => {
  const [footerData, setFooterData] = useState<FooterDataT>();

  const {windowMediumPlus} = useWindowMedia();
  const {getFooterData} = useContentful();

  useEffect(() => {
    getFooterData().then((data) => {
      setFooterData(data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <footer ref={reference} id='contact-section'>
      <div className='container'>
        <div className='footer-upper'>
          <div className='footer__info-box'>
            <div className='info-box__item'>
              <Link to='/' className='logo' href='#'>
                FREIGHTPOSTER
              </Link>
            </div>
            <div className='info-box__item hero-info-text'>
              <p>{footerData?.aboutCompany}</p>
            </div>
            <div className='info-box__item'>
              <div className='footer-cta'>
                <NavLink
                  to={'/signin'}
                  className='home-button footer-cta--button'
                  href='#'>
                  {footerData?.actionButtonText}
                </NavLink>
              </div>
            </div>
          </div>
          <div className='footer__info-box'>
            <div className='info-box__item'>
              <h5>{footerData?.contactTitle}</h5>
            </div>
            <div className='info-box__item'>
              <div className='info-box__item--iconBox'>
                <SvgIcon component={Email} className='info-box__icon' />
                <a
                  style={{color: 'var(--accent-color)'}}
                  href={`mailto:${footerData?.contactEmail}`}>
                  {footerData?.contactEmail}
                </a>
              </div>
            </div>
            <div className='info-box__item'>
              <div className='info-box__item--iconBox'>
                <SvgIcon
                  component={LocationOnOutlined}
                  className='info-box__icon'
                />
                <p style={{maxWidth: '194.8px'}}>
                  {footerData?.contactLocation}
                </p>
              </div>
            </div>
            <div className='info-box__item'>
              <div className='info-box__item--iconBox'>
                <SvgIcon component={PhoneRounded} className='info-box__icon' />
                <p>{footerData?.contactPhone}</p>
              </div>
            </div>
          </div>
        </div>
        <div className='footer__lower'>
          {windowMediumPlus && (
            <div className='footer__quick-links'>
              <Link to='/' href='#'>
                {footerData?.termsText}
              </Link>
              <Link to='/privacy' href='#'>
                {footerData?.privacyText}
              </Link>
            </div>
          )}

          <p className='footer__copyright'>{footerData?.copyright}</p>
        </div>
      </div>
    </footer>
  );
};
export default FooterComponent;
