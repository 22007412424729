// import {useHistory} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import HeaderComponent from './components/header/header.component';
import HeroSection from './components/section-hero/hero-section.component';
import AboutSection from './components/section-about/about-section.component';
import FooterComponent from './components/footer/footer.component';

import ImgWithActionsSection from './components/section-video/video-section.component';
import FeaturesSection from './components/section-features/features-section.component';
import FormSection from './components/section-form/form-section';
import {useInView} from 'react-intersection-observer';
import {NavLinkT} from './home.types';
import TestimonialsSection from './components/section-partners/partners-section.component';

const HomePage = () => {
  const [activeLink, setActiveLink] = useState<NavLinkT | null>(null);
  const [isHeaderSticky, setIsHeaderSticky] = useState(false);

  const inViewOptions = {
    triggerOnce: false,
    trackVisibility: true,
    delay: 100,
  };

  const [heroSectionRef, heroInView] = useInView(inViewOptions);
  const [aboutSectionRef, aboutInView] = useInView(inViewOptions);
  const [featuresSectionRef, featuresInView] = useInView({
    ...inViewOptions,
    threshold: 0.7,
  });
  const [signUpSectionRef, signUpInView] = useInView({
    ...inViewOptions,
    threshold: 0.7,
  });

  const [contactSectionRef, contactInView] = useInView({
    ...inViewOptions,
    threshold: 0.7,
  });

  const [testimonialsSectionRef, testimonialsInView] = useInView({
    ...inViewOptions,
    threshold: 0.7,
  });

  useEffect(() => {
    if (aboutInView && !heroInView) {
      setActiveLink('about');
    } else if (heroInView && aboutInView) {
      setActiveLink(null);
    }
    if (featuresInView) setActiveLink('features');
    if (signUpInView) setActiveLink('sign-up');
    if (testimonialsInView) setActiveLink('testimonials');
    if (contactInView) setActiveLink('contact');
  }, [
    aboutInView,
    heroInView,
    featuresInView,
    signUpInView,
    contactInView,
    testimonialsInView,
  ]);

  return (
    <div className='home'>
      <HeaderComponent
        isSticky={isHeaderSticky}
        setIsSticky={setIsHeaderSticky}
        activeLink={activeLink}
        setActiveLink={setActiveLink}
      />
      <HeroSection inView={heroInView} reference={heroSectionRef} />
      <AboutSection reference={aboutSectionRef} />
      <ImgWithActionsSection />
      <FeaturesSection reference={featuresSectionRef} />
      <FormSection reference={signUpSectionRef} />
      <TestimonialsSection reference={testimonialsSectionRef} />
      <FooterComponent reference={contactSectionRef} />
    </div>
  );
};

export default HomePage;
