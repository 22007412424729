import {Dispatch} from 'redux';

import {axiosService} from '../../services';
import {fetchError, fetchStart, fetchSuccess} from './Common';
import {AppActions} from '../../types';
import {FormLocation} from 'modules/organisms';
import {SET_PROPOSAL_SEEN} from 'types/actions/Load.action';

export const setProposalSeen = (data: {loadId: any; lastSeenDate: any}) => ({
  type: SET_PROPOSAL_SEEN,
  proposalSeen: data,
});

export const onLoadCreate = (
  data: any,
  locations: FormLocation[],
  history: any,
) => {
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      await axiosService.loadCreate(data, locations);
      dispatch(fetchSuccess());
      history.push('/loads/shipper');
    } catch (err: any) {
      dispatch(fetchError(err));
    }
  };
};

export const onGetSavedLocations = () => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(fetchStart());
    try {
      const res: any = await axiosService.savedLocationsGet();

      dispatch(fetchSuccess());
      return Promise.resolve(res?.data);
    } catch (err: any) {
      dispatch(fetchError(err));
    }
  };
};

export const onLoadGet = (loadId: string) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(fetchStart());
    try {
      const res: any = await axiosService.loadGet(loadId);
      console.log(res);

      dispatch(fetchSuccess());
      return Promise.resolve(res);
    } catch (err: any) {
      dispatch(fetchError(err));
    }
  };
};

export const onLoadEdit = (
  data: any,
  locations: FormLocation[],
  history: any,
  loadid: string,
) => {
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());

    try {
      const res = await axiosService.loadEdit(data, locations, loadid);
      dispatch(fetchSuccess());
      history.push('/loads/shipper');
      return Promise.resolve(res);
    } catch (err: any) {
      dispatch(fetchError(err));
    }
  };
};

export const onLoadExpirationEdit = (loadId: number, date: string) => {
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const res = await axiosService.loadExpirationEdit(loadId, date);
      dispatch(fetchSuccess());
      return Promise.resolve(res);
    } catch (err: any) {
      dispatch(fetchError(err));
    }
  };
};

export const onLoadBatchUpload = (file: File) => {
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const res = await axiosService.loadBatchUpload(file);
      dispatch(fetchSuccess());
      return Promise.resolve(res);
    } catch (err: any) {
      dispatch(fetchError(err));
    }
  };
};

export const onLoadCancel = (id: string, message: string) => {
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const res = await axiosService.loadCancel(id, message);
      dispatch(fetchSuccess());
      return Promise.resolve(res);
    } catch (err: any) {
      dispatch(fetchError(err));
    }
  };
};
