import * as yup from 'yup';

export const FormLoadCreateDetailsValidationSchema = () => {
  return yup.object({
    weight: yup.number().required('Please enter weight'),
    refNumber: yup.string().required('Please enter reference number'),
    commodity: yup.string().required('Please enter commodity'),
    loadAccessoriesSelect: yup.string().required('Please enter load accessories')
  });
};
