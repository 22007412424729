import {SvgIcon} from '@material-ui/core';
import {Close, Menu} from '@material-ui/icons';
import {Drawer} from '@mui/material';
import React from 'react';
import {MenuDrawerT} from '../../home.types';

import './menuDrawer.styles.css';

const MenuDrawer = ({
  navLinks,
  navLinkColor,
  handleNavLinkChange,
  isOpen,
  setIsOpen,
}: MenuDrawerT) => {
  return (
    <>
      <SvgIcon
        component={Menu}
        onClick={() => setIsOpen(true)}
        className='menu-btn'
      />
      <Drawer
        transitionDuration={350}
        anchor='right'
        open={isOpen}
        onClose={() => setIsOpen(false)}>
        <SvgIcon
          onClick={() => setIsOpen(false)}
          component={Close}
          className='drawer-close'
        />
        {navLinks.map((link) => (
          <div
            key={link}
            style={{color: navLinkColor(link)}}
            onClick={() => handleNavLinkChange(link)}
            className='drawer-option'>
            {link}
          </div>
        ))}
      </Drawer>
    </>
  );
};

export default MenuDrawer;
