import {GoogleMap, InfoWindow, Marker} from '@react-google-maps/api';
import React, {useEffect, useState} from 'react';
import ReactGoogleMapsLoader from 'react-google-maps-loader';

import {locationTypes} from 'shared/constants/AppConst';
import currentLocationIcon from './currentLocation.png';
import {MapMarker, MapWithMarkersProps} from './map-with-markers.types';
import specialMarkerIcon from './specialMarker.png';

export const MapWithMarkers: React.FC<MapWithMarkersProps> = (props) => {
  const {markers, showInfo, mapHeight, polyline, currentLocation} = props;

  const [center, setCenter] = useState({
    lat: 0,
    lng: 0,
  });
  const [showInfoIndex, setShowInfoIndex] = useState(-1);

  const renderPolyline = (map: google.maps.Map<Element>) => {
    if (polyline !== null && polyline !== undefined && polyline.length > 0) {
      let flightPath = new window.google.maps.Polyline({
        path: polyline,
        geodesic: true,
        strokeColor: '#FF0000',
        strokeOpacity: 1.0,
        strokeWeight: 2,
      });
      flightPath.setMap(map);
    }
  };
  const markerAddress = (marker: MapMarker) => {
    const markersLocationType = locationTypes.find(
      (locType) => `${locType.id} ` === marker.type,
    );

    if (markersLocationType)
      return markersLocationType.name + ': ' + marker.address;
  };

  useEffect(() => {
    if (markers.length > 0) {
      setCenter({lat: +markers[0].latitude, lng: +markers[0].longitude});
    }
  }, [markers]);

  useEffect(() => {
    if (props.center !== null && props.center !== undefined) {
      setCenter(props.center);
    }
  }, [props.center]);

  return (
    <ReactGoogleMapsLoader
      params={{
        key: process.env.REACT_APP_GOOGLE_MAP_KEY ?? '',
        libraries: 'places,geocode',
      }}
      render={(googleMaps) => {
        const map = googleMaps && (
          <GoogleMap
            mapContainerStyle={{width: '100%', height: mapHeight}}
            center={center}
            onLoad={(map) => {
              let bounds = new window.google.maps.LatLngBounds();
              markers.forEach((marker) => {
                bounds.extend({lat: +marker.latitude, lng: +marker.longitude});
              });
              map.fitBounds(bounds);
              renderPolyline(map);
            }}
            zoom={1}>
            {markers.map((marker, index) => {
              if (marker.type === 'specialMarker') {
                return (
                  <Marker
                    key={'markerLocation'}
                    position={{
                      lat: +marker.latitude,
                      lng: +marker.longitude,
                    }}
                    icon={specialMarkerIcon}></Marker>
                );
              }
              return (
                <Marker
                  key={'marker' + index}
                  position={{lat: +marker.latitude, lng: +marker.longitude}}
                  onClick={() => {
                    if (showInfo) {
                      setShowInfoIndex(index);
                    }
                  }}>
                  {showInfoIndex === index && (
                    <InfoWindow
                      onCloseClick={() => {
                        setShowInfoIndex(-1);
                      }}>
                      <div style={{color: 'black'}}>
                        <div>{markerAddress(marker)}</div>
                        {marker.contactDetails && (
                          <>
                            <div>{'------------'}</div>
                            <div>{marker.contactDetails.name}</div>
                            <div>
                              {'Name: ' + marker.contactDetails.contactName}
                            </div>
                            <div>
                              {'Phone: ' + marker.contactDetails.contactPhone}
                            </div>
                            <div>{'Note: ' + marker.contactDetails.notes}</div>
                          </>
                        )}
                      </div>
                    </InfoWindow>
                  )}
                </Marker>
              );
            })}
            {currentLocation && (
              <Marker
                key={'markerLocation'}
                position={{
                  lat: +currentLocation.lat,
                  lng: +currentLocation.lng,
                }}
                icon={currentLocationIcon}
              />
            )}
          </GoogleMap>
        );
        return map;
      }}
    />
  );
};
