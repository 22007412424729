import {SvgIcon} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import {Devices, Cast, DescriptionOutlined} from '@material-ui/icons';

import './about-section.styles.css';
import {SectionsRefT} from '../../home.types';
import useContentful from 'hooks/useContentful';
import {AboutCardT} from 'hooks/types';

const AboutSection = ({reference}: {reference: SectionsRefT}) => {
  const [aboutCards, setAboutCards] = useState<AboutCardT[]>();

  const {getAboutCardsData} = useContentful();

  useEffect(() => {
    getAboutCardsData().then((cards) => {
      if (cards) {
        setAboutCards(cards);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section ref={reference} id='about-section' className='about-section'>
      <div className='about-lower container'>
        {aboutCards?.map((card, i) => {
          const icon = i === 0 ? Cast : i === 1 ? DescriptionOutlined : Devices;
          return (
            <div key={card.title} className='about-card'>
              <div className='about-card__content-box'>
                <SvgIcon component={icon} className='about-card__icon' />
                <h4>{card.title}</h4>
              </div>
              <p>{card.description}</p>
            </div>
          );
        })}
      </div>
    </section>
  );
};
export default AboutSection;
