import React from 'react';

export const otherConfig = [
  {
    routes: [
      {
        path: '/terms',
        exact: true,
        component: React.lazy(() => import('./terms/terms.page')),
      },
    ],
  },
  {
    routes: [
      {
        path: '/privacy',
        exact: true,
        component: React.lazy(() => import('./privacy/privacy.page')),
      },
    ],
  },
];
