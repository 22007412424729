import React, {useEffect, useState} from 'react';
import {SectionsRefT} from '../../home.types';

import {Autoplay} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';

import 'swiper/swiper.min.css';
import './partners-section.styles.css';
import {PartnersSectionDataT} from 'hooks/types';
import useContentful from 'hooks/useContentful';

const PartnersSection = ({reference}: {reference: SectionsRefT}) => {
  const [partnersSectionData, setPartnersSectionData] =
    useState<PartnersSectionDataT>();

  const {getPartnersSectionData} = useContentful();

  useEffect(() => {
    getPartnersSectionData().then((data) => {
      setPartnersSectionData(data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section
      id='testimonials-section'
      ref={reference}
      className='testimonials-section'>
      <div className='container'>
        <div className='testimonials-upper'>
          <h2 style={{marginBottom: 60, fontWeight: 600, textAlign: 'center'}}>
            {partnersSectionData?.title}
          </h2>
        </div>
        <div className='testimonials-lower'>
          {partnersSectionData && (
            <Swiper
              slidesPerView={'auto'}
              autoplay={{delay: 0, disableOnInteraction: false}}
              speed={1800}
              modules={[Autoplay]}
              loop
              spaceBetween={60}
              pagination={{
                clickable: true,
              }}
              className='mySwiper'>
              {partnersSectionData.partners.map((partner) => (
                <SwiperSlide>
                  <img key={partner} src={partner} alt={partner} />
                </SwiperSlide>
              ))}
            </Swiper>
          )}
        </div>
      </div>
    </section>
  );
};

export default PartnersSection;
