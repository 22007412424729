import React, {useEffect} from 'react';
import {useFormikContext, useField} from 'formik';

import {Box, Button, Checkbox, Grid} from '@material-ui/core';
import {GridContainer} from '@crema';

import {FormLocationSelectorProps} from './form-location-selector.types';
import {locationTypes} from 'shared/constants/AppConst';

import {FormSelectPicker, FormGooglePlaces, FormTextField} from 'modules/atoms';
import AppCard from '@crema/core/AppCard';

export const FormLocationSelector: React.FC<FormLocationSelectorProps> = (
  props,
) => {
  const {setFieldValue} = useFormikContext();
  const [field] = useField('locationType' + props.location.id);

  const id = props.location.id;

  const removeLocation = () => {
    setFieldValue('locationType' + id, undefined);
    setFieldValue('locationName' + id, undefined);
    setFieldValue('contactName' + id, undefined);
    setFieldValue('contactPhone' + id, undefined);
    setFieldValue('googlePlaceLat' + id, undefined);
    setFieldValue('googlePlaceLng' + id, undefined);
    setFieldValue('googlePlaceName' + id, undefined);
    setFieldValue('number' + id, undefined);
    props.removeLocation(id);
  };

  useEffect(() => {
    setFieldValue('locationType' + id, props.location.typeId);
    setFieldValue('locationName' + id, props.location.name);
    setFieldValue('contactName' + id, props.location.contactName);
    setFieldValue('contactPhone' + id, props.location.contactPhone);
    setFieldValue('googlePlaceLat' + id, Number(props.location.lat));
    setFieldValue('googlePlaceLng' + id, Number(props.location.long));
    setFieldValue('googlePlaceName' + id, props.location.placeName);
    setFieldValue('number' + id, props.location.number);
  }, []);

  const pickupNumber = (typeId: number) => {
    if (typeId === 1) {
      return 'Pickup number';
    } else if (typeId === 4) {
      return 'Dropoff number';
    } else {
      return '';
    }
  };

  // const savedLocationIds = props.savedLocations()

  return (
    <AppCard style={{paddingLeft: 20, paddingRight: 20}} mb={3}>
      <Box style={{width: '100%', height: 500}}>
        <Box mt={5}>
          <GridContainer>
            <Grid item xs={12} md={6}>
              <Checkbox
                onChange={(e) => {
                  console.log(e.target.checked);

                  setFieldValue('saveLocation' + id, e.target.checked);
                }}
                name={'saveLocation' + id}
              />
              Save this location
            </Grid>
            <Grid item xs={12} md={6}>
              {props.savedLocations.length > 0 ? (
                <FormSelectPicker
                  onChange={(value) => {
                    const filteredLocation = props.savedLocations.filter(
                      (loc: any) => loc.id === value,
                    );

                    setFieldValue('savedLocationId' + id, value);

                    setFieldValue(
                      'googlePlaceName' + id,
                      filteredLocation[0].address,
                    );
                    setFieldValue(
                      'locationName' + id,
                      filteredLocation[0].name,
                    );
                    setFieldValue(
                      'googlePlaceLat' + id,
                      Number(filteredLocation[0].latitude),
                    );
                    setFieldValue(
                      'googlePlaceLng' + id,
                      Number(filteredLocation[0].longitude),
                    );

                    setFieldValue(
                      'contactName' + id,
                      filteredLocation[0].contactName,
                    );
                    setFieldValue(
                      'contactPhone' + id,
                      filteredLocation[0].contactPhone,
                    );
                    setFieldValue('number' + id, filteredLocation[0].number);

                    setFieldValue(
                      'locationType' + id,
                      filteredLocation[0].locationType,
                    );
                  }}
                  name={'location' + props.location.id}
                  items={props.savedLocations}
                  label='Select from saved locations'
                />
              ) : (
                <div>No saved locations</div>
              )}
            </Grid>
          </GridContainer>
          <GridContainer mb={5}>
            <Grid item xs={12} md={6}>
              <FormGooglePlaces location={props.location} />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormSelectPicker
                name={'locationType' + props.location.id}
                items={locationTypes}
                label='Select location type'
              />
              <Box mt={5}>
                <FormTextField
                  label='Facility Name *'
                  name={'locationName' + props.location.id}
                  type='text'
                />
              </Box>

              <Box mt={5}>
                <FormTextField
                  label='Facility Contact Name'
                  name={'contactName' + props.location.id}
                  type='text'
                />
              </Box>
              <Box mt={5}>
                <FormTextField
                  label='Facility Contact Phone number'
                  name={'contactPhone' + props.location.id}
                  type='text'
                />
              </Box>
              {pickupNumber(field.value) !== '' && (
                <Box mt={5}>
                  <FormTextField
                    label={pickupNumber(field.value)}
                    name={'number' + props.location.id}
                    type='text'
                  />
                </Box>
              )}
              <Box mt={5}>
                <Button
                  variant='contained'
                  color='secondary'
                  onClick={removeLocation}>
                  Remove location
                </Button>
              </Box>
            </Grid>
          </GridContainer>
        </Box>
      </Box>
    </AppCard>
  );
};
